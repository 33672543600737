import * as React from "react"

import Seo from "../components/seo"
import Review from "../components/Review"
import Carousel from 'react-material-ui-carousel'
import { StaticImage } from "gatsby-plugin-image"
import { HomeStrings as Home } from "../content/HomeText"
import { PPEButton } from "../components/PPEButton"
import { Colors, Padding } from "../components/Styles"
import { useEffect, useState } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

const reviews = [
  <Review name={Home.reviews.review1.name} review={Home.reviews.review1.review} />,
  <Review name={Home.reviews.review2.name} review={Home.reviews.review2.review} />,
  <Review name={Home.reviews.review3.name} review={Home.reviews.review3.review} />
]

const IndexPage = () => {

  const [isDesktop, setDesktop] = useState(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 920)
    }
  }, [])

  const updateMedia = () => {
    setDesktop(window.innerWidth > 920)
  }

  useEffect(() => {
    window.addEventListener("resize", updateMedia)
    return () => window.removeEventListener("resize", updateMedia)
  })

  const images = [
    <StaticImage
      src="../images/home1.jpg"
      loading="eager"
      quality={95}
      formats={["auto", "webp", "avif"]}
      objectFit="cover"
      style={{ height: "600px" }}
      layout="fullWidth"
      alt="" />,
    <StaticImage
      src="../images/home2.jpg"
      loading="eager"
      quality={95}
      formats={["auto", "webp", "avif"]}
      objectFit="cover"
      layout="fullWidth"
      style={{ height: "600px" }}
      alt="" />,
    <StaticImage
      src="../images/home3.jpg"
      loading="eager"
      quality={95}
      objectFit="cover"
      style={{ height: "600px" }}
      layout="fullWidth"
      formats={["auto", "webp", "avif"]}
      alt="" />,
  ]
  if (isDesktop == true) {
    return (
      <div>
        <Seo title="Home" />
        <div style={{ display: "flex", backgroundColor: Colors.cream, minHeight: "400px" }}>
          <StaticImage
            src="../images/homeheader.jpg"
            loading="auto"
            quality={100}
            objectFit="cover"
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            alt="Floral Arrangement from a Sparkle Celebrations Event"
            style={{ zIndex: "90", width: '50%' }} />
          <div style={{ width: `45%`, display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: Padding.gutter, marginTop: "15px", marginBottom: "15px" }}>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
              <h1>{Home.title}</h1>
              <h2>{Home.description}</h2>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
              <PPEButton buttonText="About" link="/About" centered />
            </AnimationOnScroll>
          </div>
        </div>
        <div style={{ display: "inline-flex", width: '100%' }}>
          <div style={{ backgroundColor: Colors.pink, width: `50%`, paddingLeft: Padding.gutter, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInRight">
              <h1>{Home.servicesTitle}</h1>
              <h2>{Home.servicesDescription}</h2>
            </AnimationOnScroll>
            <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
              <PPEButton buttonText="Services" link="/Services" centered />
            </AnimationOnScroll>
          </div>

          <Carousel
            sx={{ width: "50%", height: "600px" }}
            navButtonsProps={{
              style: {
                backgroundColor: Colors.purple
              }
            }}
            activeIndicatorIconButtonProps={{
              style: {
                color: Colors.purple
              }
            }}
            indicatorIconButtonProps={{
              style: {
                color: Colors.white
              }
            }}>
            {
              images.map((image) => <>{image}</>)
            }
          </Carousel>
        </div>
        <div style={{ backgroundColor: Colors.cream, padding: Padding.gutter }}>
          <h1>Customer Reviews</h1>
          <Carousel navButtonsProps={{
            style: {
              backgroundColor: Colors.purple
            }
          }}
            activeIndicatorIconButtonProps={{
              style: {
                color: Colors.purple
              }
            }}
            indicatorIconButtonProps={{
              style: {
                color: Colors.white
              }
            }}
          >
            {
              reviews.map((review) => review)
            }
          </Carousel>
        </div>
      </div >
    )
  }
  else if (isDesktop == false) return (
    <div style={{ paddingTop: "95px" }}>
      <div style={{ width: `100%`, justifyContent: 'center', padding: Padding.mobileGutter }}>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInRight">
          <h1>{Home.title}</h1>
          <h2>{Home.description}</h2>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
          <PPEButton mobile buttonText="About" link="/About" centered />
        </AnimationOnScroll>
      </div>
      <StaticImage
        src="../images/homeheader.jpg"
        style={{ zIndex: "90" }}
        loading="auto"
        quality={100}
        formats={["auto", "webp", "avif"]}
        alt="Floral Arrangement from a Sparkle Celebrations Event" />
      <div style={{ backgroundColor: Colors.pink, width: `100%`, padding: Padding.mobileGutter, justifyContent: 'center' }}>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInRight">
          <h1>{Home.servicesTitle}</h1>
          <h2>{Home.servicesDescription}</h2>
        </AnimationOnScroll>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
          <PPEButton mobile buttonText="Services" link="/Services" centered />
        </AnimationOnScroll>
      </div>
      <StaticImage
        src="../images/home1.jpg"
        loading="auto"
        quality={100}
        objectFit="fill"
        layout="fullWidth"
        formats={["auto", "webp", "avif"]}
        alt="Floral Arrangement from a Sparkle Celebrations Event" />
      <div style={{ width: `100%`, padding: Padding.mobileGutter, justifyContent: 'center' }}>
        <h1>Customer Reviews</h1>
        <Carousel
          navButtonsProps={{
            style: {
              backgroundColor: Colors.purple
            }
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: Colors.purple
            }
          }}
          indicatorIconButtonProps={{
            style: {
              color: Colors.white
            }
          }}
        >
          {
            reviews.map((review) => review)
          }
        </Carousel>
      </div>
    </div>
  )
}
export const Head = () => <Seo title="Home" />

export default IndexPage
