export const HomeStrings = {
    title: "Welcome",
    description: "We pride ourselves in being Colorado's premier destination for birthday party, wedding, & baby shower design, planning, and event set-up.",
    servicesTitle: "Custom Packages",
    servicesDescription: "From set-up to day-of coordination, we’ve got you covered! Contact us for a complimentary consultation and custom package quote.",
    reviews: {
        review1: {
            name: "Alexandria Hardin, VA",
            review: "Recently I was in a wedding as a bridesmaid and the hired event coordinator never showed up. Another fellow bridesmaid, a co-owner of Sparkle Celebrations, was able to get everything under control & make sure everything ran smoothly. Though she had obviously not come prepared to step into this role, she really stepped up to the plate and made this a flawless wedding- so much so, that when a substitute wedding coordinator finally did arrive 2 hours late, everybody working the wedding was still turning  to Charlotte for direction. The event was an absolute hit and the newlywed couple was very satisfied with the outcome. I highly recommend Sparkle Celebrations as a result."
        },
        review2: {
            name: "Lori W, CO",
            review: "You ladies succeeded in turning our 4th of July gathering into an actual fantastic event with your great decorations and awesome treat and food platters! You absolutely have an eye and talent for creating a yummy, festive atmosphere! Thank you so very much! I will be looking to you for our next event with your talented party planning skills!"
        },
        review3: {
            name: "Lynne T, CO",
            review: "Using their unique gifts of creativity, the women at Sparkle Celebrations create a festive, welcome, and well-organized event for any occasion. They take the worry and frustration out of the planning process and free-up the host’s time to enjoy guests and activities!"
        },
    },
}