
import * as React from "react"
import { Colors } from "./Styles"

const Review = ({ name, review }) => (
    <div style={{ color: Colors.slate }}>
        <h2>{name}</h2>
        <h3>{review}</h3>
    </div>
)

export default Review